<script lang="ts" setup>
const { t } = useT();
const { open } = useFunrizeModals();
const { depositInfoData, durationLeftResetStreak } = useFunrizeDepositStreakData({ immediate: false });

const handleClick = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "left_menu",
		button_name: "deposit_streak"
	});

	open("LazyOModalDepositStreak");
};
</script>

<template>
	<div class="wheel-banner" @click="handleClick">
		<AText v-if="depositInfoData.dailyWinStreakLimitExceeded" class="timer" variant="topeka">
			<i18n-t keypath="Available in {key}">
				<template #key>
					<AText class="text-cannes" :modifiers="['bold']">{{ durationLeftResetStreak }}</AText>
				</template>
			</i18n-t>
		</AText>
		<LazyMDepositStreakProgressBar v-else :steps="depositInfoData.progress">
			<template #top>
				<AText
					:class="`text-${depositInfoData.canWheel ? 'cixi' : 'cannes'}`"
					variant="topeka"
					:modifiers="['bold']"
					as="div"
				>
					{{ depositInfoData.canWheel ? t("Get prize") : t("Prize Machine") }}
				</AText>
			</template>
			<template #bottom>
				<AText v-if="depositInfoData.canWheel" class="text-cannes" variant="topeka" as="div">
					{{ t("Prize Machine Unlocked") }}
				</AText>
				<AText v-else class="progress-text text-caracas" variant="ternopil" as="div">
					<i18n-t keypath="{key} Purchases Completed">
						<template #key>
							{{ `${depositInfoData?.currentCountDeposit}/${depositInfoData?.maxNumberOfDeposit}` }}
						</template>
					</i18n-t>
				</AText>
			</template>
		</LazyMDepositStreakProgressBar>
		<LazyNuxtImg
			class="wheel-img"
			src="/nuxt-img/deposit-streak/sidebar-icon.png"
			format="webp"
			alt="deposit-streak"
			width="112"
			height="112"
		/>
	</div>
</template>

<style scoped lang="scss">
.wheel-banner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	height: 54px;
	background: var(--goma);
	border-radius: 8px;
	position: relative;
	cursor: pointer;
	padding-left: 8px;

	.wheel-img {
		width: 56px;
		height: 56px;
	}

	.timer {
		display: flex;
		flex-direction: column;
		gap: 4px;
		color: var(--calgary);
	}
}
</style>
